*{
  box-sizing:border-box;
}
body {
  image-rendering: pixelated;
  background-color: #111a75;
}
.frame{
  background-image: url(../static/splas_og.png);
  height: calc(100vh - 100px);
  background-repeat: no-repeat;
  background-position: top center;
  background-size: auto 100%;
  margin: 50px auto;
  padding: 1px 0 0 0;
}
@keyframes float {
  0%    {margin-top: -1%;}
  50%   {margin-top: 1%;}
  100%  {margin-top: -1%;}
}
.logo-wrapper{
  .logo{
    margin: calc(min(100vw, 800px) * 0.05) auto 0;
    max-width: 90 vw;
    display: block;
    filter: drop-shadow(5px 5px 3px #000)
      drop-shadow(0px 0px 50px rgba(255, 255, 255, 1))
      drop-shadow(0px 0px 50px rgba(255, 255, 255, 0.5));
    padding-bottom: 25vh;
    /*animation-name: float;
    animation-duration: 10s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;*/
  }
}
/*@media (max-aspect-ratio: 4/4) {
  .logo-wrapper{
    .logo{
      height: auto;
      width: 95vw;
      filter: drop-shadow(5px 5px 3px #000)
        drop-shadow(0px 0px 10vw rgba(255, 255, 255, 1))
        drop-shadow(0px 0px 10vw rgba(255, 255, 255, 1));
    }
  }
}
*/
