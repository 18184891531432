* {
  box-sizing: border-box;
}

body {
  image-rendering: pixelated;
  background-color: #111a75;
}

.frame {
  height: calc(100vh - 100px);
  background-image: url("splas_og.e0c7d293.png");
  background-position: top;
  background-repeat: no-repeat;
  background-size: auto 100%;
  margin: 50px auto;
  padding: 1px 0 0;
}

@keyframes float {
  0% {
    margin-top: -1%;
  }

  50% {
    margin-top: 1%;
  }

  100% {
    margin-top: -1%;
  }
}

.logo-wrapper .logo {
  max-width: 90 vw;
  filter: drop-shadow(5px 5px 3px #000) drop-shadow(0 0 50px #fff) drop-shadow(0 0 50px #ffffff80);
  margin: calc(min(100vw, 800px) / 20) auto 0;
  padding-bottom: 25vh;
  display: block;
}

/*# sourceMappingURL=splash.7a0701d3.css.map */
